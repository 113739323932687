import request from '../utils/request'

// login
export function ssoLogin(params) {
  return request({
    url: '/sso/login',
    method: 'POST',
    params: params
  })
}


// 获取用户信息
export function ssoInfo(params) {
  return request({
    url: '/sso/info',
    method: 'GET',
    params: params
  })
}

export function systemConfig(code) {
  return request({
    url: '/systemConfig/' + code,
    method: 'GET'
  })
}

export function ssoUpdatePassword(params) {
  return request({
    url: '/sso/updatePassword',
    method: 'POST',
    params: params
  })
}

export function ssoUpdatePhone(params) {
  return request({
    url: '/sso/updatePhone',
    method: 'POST',
    params: params
  })
}

export function ssoPhoneUpdatePassword(params) {
  return request({
    url: '/sso/phone/updatePassword',
    method: 'POST',
    params: params
  })
}

export function ssoNamePhone(params) {
  return request({
    url: '/sso/namePhone',
    method: 'GET',
    params: params
  })
}

// 资费说明
export function consumption(params) {
  return request({
    url: '/banner/consumption',
    method: 'GET',
    params: params
  })
}

// 列表
export function writePage(params) {
  return request({
    url: '/write/page',
    method: 'GET',
    params: params
  })
}

// 创建软件
export function writeCreate (data) {
  return request({
    url: '/write/create',
    method: 'POST',
    data
  })
}

// 根据软件名称生成目的-描述-主要功能
export function writeDescription (data) {
  return request({
    url: '/write/description',
    method: 'POST',
    data
  })
}

// 根据软件名称返回大纲
export function writeOutline (data) {
  return request({
    url: '/write/outline',
    method: 'POST',
    data
  })
}

// 保存更新申请表
// export function writeUpdate (data) {
//   return request({
//     url: '/write/update',
//     method: 'POST',
//     data
//   })
// }

// export function writeUpdate (data) {
//   return request({
//     url: '/v2/write/update',
//     method: 'POST',
//     data
//   })
// }

export function writeUpdate (data) {
  return request({
    url: '/v2/write/v2/update',
    method: 'POST',
    data
  })
}

// 保存更新大纲
export function writeOutlineUpdate (data) {
  return request({
    url: '/write/outline/update',
    method: 'POST',
    data
  })
}

// 保存更新大纲
// export function writeOutlineUpdate (data) {
//   return request({
//     url: '/v2/write/outline/update',
//     method: 'POST',
//     data
//   })
// }

// 根据大纲返回正文(同步)
export function writeMain (data) {
  return request({
    url: '/write/main',
    method: 'POST',
    data
  })
}

// 根据大纲返回正文(异步)
export function writeContent (data) {
  return request({
    url: '/write/content',
    method: 'POST',
    data
  })
}

// 正文列表
export function writeContentList(params) {
  return request({
    url: '/write/content',
    method: 'GET',
    params: params
  })
}

export function writeContentText(contentId) {
  return request({
    url: '/write/content/' + contentId,
    method: 'GET'
  })
}

// 保存更新正文
export function writeContentUpdate (data) {
  return request({
    url: '/write/content/update',
    method: 'POST',
    data
  })
}

// 根据功能正文返回html和截图
export function writeHtml (data) {
  return request({
    url: '/write/html',
    method: 'POST',
    data
  })
}

// 导出软件-完整的包
export function writeExport(params) {
  return request({
    url: '/write/export',
    method: 'GET',
    params: params
  })
}

// 导出软件-用户手册word
export function writeExportContent(params) {
  return request({
    url: '/write/export/content',
    method: 'GET',
    params: params
  })
}

export function writeDel (data) {
  return request({
    url: '/write/del/' + data.id,
    method: 'DELETE'
  })
}

// 获取申请表基本信息
export function writeSoftware(id) {
  return request({
    url: '/write/software/' + id,
    method: 'GET'
  })
}

// 改-扩写
export function writeRewrite (data) {
  return request({
    url: '/write/rewrite',
    method: 'POST',
    data
  })
}

// 文生图
export function TextToImage (data) {
  return request({
    url: '/write/textToImage',
    method: 'POST',
    data
  })
}

export function alipayForm(data) {
  return request({
    url: '/pay/repair/pay-form-web',
    method: 'POST',
    data
  })
}

export function createPayLog (data) {
  return request({
    url: '/pay/createPayLog',
    method: 'POST',
    data
  })
}

export function writeScreenshot (data) {
  return request({
    url: '/write/screenshot',
    method: 'POST',
    data
  })
}

export function feedBack (data) {
  return request({
    url: '/feedBack',
    method: 'POST',
    data
  })
}

export function writeRepeat (params) {
  return request({
    url: '/write/repeat',
    method: 'GET',
    params
  })
}

export function htmlDownload (params) {
  return request({
    url: '/write/html/download/' + params.writeId,
    method: 'GET'
  })
}

export function writeDesignate (params) {
  return request({
    url: '/write/v2/designate',
    method: 'GET',
    params
  })
}

export function writeDesignateV3 (data) {
  return request({
    url: '/write/v3/designate',
    method: 'POST',
    data
  })
}

export function agentDomain (params) {
  return request({
    url: '/admin/agent/byDomain',
    method: 'GET',
    params
  })
}

export function writeAnew (writeId) {
  return request({
    url: '/write/anew/' + writeId,
    method: 'GET'
  })
}

export function exportCode (params) {
  return request({
    url: '/write/export/code',
    method: 'GET',
    params
  })
}

export function writeApplicationUpdate (data) {
  return request({
    url: '/v2/write/application/update',
    method: 'PUT',
    data
  })
}

export function writeDocumentation (params) {
  return request({
    url: '/v2/write/documentation/' + params.writeId,
    method: 'GET',
    params
  })
}

export function getCopyrightAccountToken (params) {
  return request({
    url: '/copyright/accountToken',
    method: 'GET',
    params
  })
}

export function saveCopyrightAccountToken (data) {
  return request({
    url: '/copyright/accountToken',
    method: 'POST',
    data
  })
}

export function copyrightSubmit (data) {
  return request({
    url: '/copyright/submit',
    method: 'POST',
    data
  })
}

export function copyrightGetParam (params) {
  return request({
    url: '/copyright/getParam',
    method: 'GET',
    params
  })
}



export function writeCheck (params) {
  return request({
    url: '/write/verify',
    method: 'POST',
    params
  })
}

export function writeDetail (params) {
  return request({
    url: '/write/detail',
    method: 'GET',
    params
  })
}

export function writeAnewFile (data) {
  return request({
    url: '/write/anew/file',
    method: 'POST',
    data
  })
}

export function writeUpdateFile (data) {
  return request({
    url: '/write/update/file',
    method: 'POST',
    data
  })
}

export function copyrightAddInformation (data) {
  return request({
    url: '/copyright/addInformation',
    method: 'POST',
    data
  })
}

export function copyrightUploadFile (data) {
  return request({
    url: '/copyright/uploadFile',
    method: 'POST',
    data
  })
}

export function copyrightSave (data) {
  return request({
    url: '/copyright/save/' + data.writeId,
    method: 'POST'
  })
}


export function copyrightSubmitSealMaterial (data) {
  return request({
    url: '/copyright/submitSealMaterial',
    method: 'POST',
    data
  })
}

export function copyrightSbmit (data) {
  return request({
    url: '/copyright/submit/' + data.writeId,
    method: 'POST'
  })
}

export function dCreate (params) {
  return request({
    url: '/d/create',
    method: 'GET',
    params
  })
}

export function ssoRead () {
  return request({
    url: '/sso/read',
    method: 'PUT',
  })
}

export function writeAfresh(data) {
  return request({
    url: '/v2/write/afresh/' + data.writeId,
    method: 'GET',
  })
}

export function systemConfigAstrict() {
  return request({
    url: '/systemConfig/astrict',
    method: 'GET',
  })
}

// export function exeCreate(data) {
//   return request({
//     url: '/exe/create',
//     method: 'POST',
//     data
//   })
// }

export function exeCreate(data) {
  return request({
    url: '/exe/v2/create',
    method: 'POST',
    data
  })
}

export function writeEquities (params) { //权益校验
  return request({
    url: '/v2/write/equities',
    method: 'GET',
    params
  })
}

export function companyPackage (params) { //权益校验
  return request({
    url: '/company/package/package',
    method: 'GET',
    params
  })
}


export function writeHash (params) { //权益校验
  return request({
    url: '/v2/write/hash/' + params.writeId,
    method: 'GET',
    params
  })
}


export function chatParamsV2 (data) {
  return request({
    url: '/chat/paramsV2',
    method: 'POST',
    data
  })
}